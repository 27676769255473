import React, { useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Sidebar from "../../components/sideBar/SideBar";
import "./index.css";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Size from "../size/Size";
import { connect } from "react-redux";
import { Mechanized } from "./mechanized";
import { TextField } from "@material-ui/core";
import QuestionUncover from "./hinge/QuestionUncover";
import Preview from "../preview/Preview";
import { toast } from "react-toastify";

let inicialRepeat = 1;

let PreviewContainer = (props) => {
  let { type } = props.match.params;
  let name = type === "normal" ? "puerta" : "verdulero";
  return (
    <Row>
      <h4 className="preview-title">
        <span className="font-weight-bold" style={{ textTransform: "uppercase" }}>
          Esquema de {name}
        </span>
      </h4>
      <Col className="previewBack py-5 col-12">
        <Preview {...props} />
      </Col>
    </Row>
  );
};

const Mecanizado = ({
  history,
  open,
  mechanized,
  add_hinge_boolean,
  setMechanized,  // Agregamos la función para actualizar el mechanized
  ...props
}) => {
  const [currentActive, setCurrentActive] = useState(["0"]);
  const [repeat, setrepeat] = useState(inicialRepeat);
  const [hkXsPosition, setHkXsPosition] = useState({
    right: false,
    left: false,
  });

  const isHK_XSSelected = mechanized && ["HK_XS", "HK_XSBLUEMOTION"].includes(mechanized.value);

  const handleCheckboxChange = (position) => {
    setHkXsPosition((prevState) => {
      const updatedState = { ...prevState, [position]: !prevState[position] };
      
      // Verifica que al menos un checkbox esté seleccionado
      if (!updatedState.right && !updatedState.left) {
        return prevState;
      }
      return updatedState;
    });
  };

  function changeRepeat(e) {
    //let value_repeat = document.getElementById("id_repeat").value;
    setrepeat(e.target.value);
    props.setCount(e.target.value);
  }

  const toNextStep = () => {
    let ori_path = window.location.pathname;
    if (ori_path[ori_path.length - 1] === "/") {
      ori_path = ori_path.slice(0, ori_path.length - 1);
    }

    // Concatenar las posiciones seleccionadas (right/left) al mechanized si está seleccionado HK_XS
    let aventosString = "";
    if (isHK_XSSelected) {
      aventosString = Object.keys(hkXsPosition)
        .filter((key) => hkXsPosition[key])
        .join("_");

      if (!hkXsPosition.right && !hkXsPosition.left) {
        toast.warn("Debes seleccionar al menos una posición de HK-XS.");
        return;
      }
    }

    const mechanizedValueWithPosition = aventosString
      ? `${mechanized.value}_${aventosString}`
      : mechanized.value;

    setMechanized({
      ...mechanized,
      value: mechanizedValueWithPosition,  // Actualizar el mechanized con la posición concatenada
    });

    if (add_hinge_boolean && open !== "Slider" && open !== "Avento") {
      let isInvalid = document.querySelectorAll(".is-invalid").length;
      let { hinges, match } = props;
      let section = ["A", "B", "C", "D", "E"];
      if (isInvalid === 0) {
        let dimention = "";
        if (mechanized?.label === "Sin mecanizado") {
          dimention = "noHinges";
          history.push(
            `${ori_path}/hinge/${dimention}/mechanized/${mechanizedValueWithPosition}/repeat/${repeat}`
          );
        } else {
          hinges.forEach((value, key) => {
            let section_key = section[value.order - 4];
            if (value.order === 8) {
              let { open, width, height } = match.params;
              let size = parseInt(
                open === "Right" || open === "Left" ? height : width
              );
              dimention =
                key === 0
                  ? `${size - value.sizeHinge}|${section_key}`
                  : `${dimention}x${size - value.sizeHinge}|${section_key}`;
            } else {
              dimention =
                key === 0
                  ? `${value.sizeHinge}|${section_key}`
                  : `${dimention}x${value.sizeHinge}|${section_key}`;
            }
          });
          history.push(
            `${ori_path}/hinge/${dimention}/mechanized/${mechanizedValueWithPosition}/repeat/${repeat}`
          );
        }
      } else {
        toast.warn(`Hay ${isInvalid} campos con medidas incorrectas`);
      }
    } else {
      history.push(
        `${ori_path}/hinge/noHinges/mechanized/${mechanizedValueWithPosition}/repeat/${repeat}`
      );
    }
  };

  const handleAccordionClick = (targetId) => {
    var temp = currentActive;
    if (temp.includes(targetId)) {
      var index = temp.indexOf(targetId);
      if (temp.length === 1) {
        temp = [];
      } else {
        for (var i = index; i < temp.length - 1; i++) {
          temp[i] = temp[i + 1];
        }
        temp.length = temp.length - 1;
      }
    } else {
      temp.push(targetId);
    }
    setCurrentActive([...temp]);
  };

  return (
    <Row className="h-100">
      <Sidebar
        onNext={toNextStep}
        onBack={() => {
          let path = window.location.pathname.split("/width")[0];
          return history.push(path);
        }}
        disabledNext={mechanized === null}
        width="col-3"
      >
        <Accordion
          className="d-flex flex-column align-items-center"
          activeKey={currentActive}
          defaultActiveKey={["0"]}
          style={{ width: "90%" }}
          alwaysOpen
        >
          <Accordion.Item className="w-100" eventKey="0">
            <Accordion.Header
              className="d-flex justify-content-between align-items-center mt-4 mt-lg-5 w-100"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleAccordionClick("0");
              }}
            >
              <span className="font-weight-bold" style={{ color: "black", fontSize: "18px" }}>
                TIPO DE MECANIZADO <span style={{ color: "red" }}> * </span>
              </span>
            </Accordion.Header>
            <Accordion.Body className="w-100">
              <Mechanized />
            </Accordion.Body>
          </Accordion.Item>

          {isHK_XSSelected && (
            <div className="d-flex flex-column align-items-start w-100 mt-3">
              <div className="divider-vert" />
              <span
                className="font-weight-bold mb-2"
                style={{ color: "black", fontSize: "18px" }}
              >
                Avento <span style={{ color: "red" }}> * </span>
              </span>
              <div className="d-flex flex-column flex-md-row w-100" style={{ gap: "16px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="HK_XS_RIGHT"
                    checked={hkXsPosition.right}
                    onChange={() => handleCheckboxChange("right")}
                  />
                  <label className="form-check-label ms-2" htmlFor="HK_XS_RIGHT">
                    Derecha
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="HK_XS_LEFT"
                    checked={hkXsPosition.left}
                    onChange={() => handleCheckboxChange("left")}
                  />
                  <label className="form-check-label ms-2" htmlFor="HK_XS_LEFT">
                    Izquierda
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="divider-vert" />
          <div
            className="d-flex flex-column justify-content-between align-items-start w-100"
          >
            <span className="font-weight-bold" style={{ color: "black", fontSize: "18px" }}>
              CANTIDAD DE PUERTAS <span style={{ color: "red" }}> * </span>
            </span>
            <div className="d-flex align-items-center w-100 mt-3" style={{ gap: "16px" }}>
              <span> Unidades </span>
              <input
                id={`id_repeat`}
                value={repeat}
                type="number"
                className="form-control text-center"
                min="1"
                placeholder="Ejem: 10"
                style={{ maxWidth: "170px" }}
                onChange={changeRepeat}
              />
            </div>
          </div>
        </Accordion>
      </Sidebar>
      <div className="col-9" style={{ padding: "36px 63px" }}>
        <QuestionUncover preview={() => <PreviewContainer className="mt-5" {...props} />} />
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  mechanized: state.mechanized,
  add_hinge_boolean: state.hinges.add_hinge_boolean,
  open: state.open,
  hinges: state.hinges.hinge_position,
  count: state.counter,
});

const mapDispatchToProps = (dispatch) => ({
  setMechanized(mechanized) {
    dispatch({
      type: "CHANGE_MECHANIZED",
      payload: mechanized,
    });
  },
  resetMechanized() {
    dispatch({
      type: "RESET_MECHANIZED",
    });
  },
  resetCount() {
    dispatch({
      type: "RESET_REPEAT",
    });
  },
  setCount(count) {
    dispatch({
      type: "CHANGE_REPEAT",
      payload: { count },
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Mecanizado);

export default component;
